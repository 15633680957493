<template>
  <div class="page_bg">
    <div class="coupon_box">
      <!-- top -->
      <div class="coupon_top_back_tab  flex-r-sb-c">
        <van-icon name="arrow-left" @click="backPath" />
        <van-badge :content="activedIndex" color="#EA5C36">
          <div :class="actived === 1?'tab_active tab_item':'tab_item'" @click="getCouponList(1)">可用</div>
        </van-badge>
        <div :class="actived === 3?'tab_active tab_item':'tab_item'" @click="getCouponList(3)">已用</div>
        <div :class="actived === 2?'tab_active tab_item':'tab_item'" @click="getCouponList(2)">失效</div>
        <div />
      </div>
      <!-- <br>
      <van-tabs style="height:2.5rem">
        <van-tab v-for="index in 8" :key="index">
          <template #title>
            <div :class="tabIndex === index ? 'tab_item_xxx tab_item_actived':'tab_item_xxx'" @click="changeTab(index)">弗莱明</div>
          </template>
        </van-tab>
      </van-tabs> -->
      <!-- body -->
      <div class="coupon_list flex-c-c-c" style="margin-bottom: 3.4rem;">
        <transition-group name="van-slide-up">
          <template v-if="actived === 1">
            <div v-for="(item1,index1) in copuponList1" :key="index1" style="position: relative;">
              <div v-if="item1.mergeType === 0">
                <van-image width="8.93rem" height="2.29rem" :src="item1.icon" />
                <van-checkbox v-if=" showCheck && item1.couponClass === 1" v-model="item1.checked"
                              checked-color="#0767ab" class="coupon_check" @click="choosed_conpon(item1)"
                />
              </div>
              <div v-if="item1.mergeType === 1" class="hecheng_coupon flex-r-s-c">
                <div class="pirce" style="color: #ED0D0D;">
                  <span style="font-size: 0.37rem;">￥</span>{{ item1.money }}
                </div>
                <div class="msg flex-c-s-s">
                  <div style="font-size: 0.37rem;font-weight: 700;color: #333;">商城通用现金券</div>
                  <div style="font-size: 0.32rem;color: #333;">满{{ item1.atLeast }}可用</div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="actived === 2">
            <van-image v-for="(item2,index2) in copuponList2" :key="index2" width="8.93rem" height="2.29rem"
                       :src="item2.invalidIcon"
            />
          </template>
          <template v-if="actived === 3">
            <van-image v-for="(item3,index3) in copuponList3" :key="index3" width="8.93rem" height="2.29rem"
                       :src="item3.invalidIcon"
            />
          </template>
        </transition-group>
        <van-empty v-if="copuponList1.length === 0 && actived === 1" class="custom-image"
                   image="https://oss.xiaoyi120.com/shop2.0/public/coupon_empty.png"
                   description="没有可用的优惠券呢..."
        >
          <!-- <van-button round class="bottom-button" color="#0767AB">去领取</van-button> -->
        </van-empty>
        <van-empty v-if="copuponList2.length === 0 && actived === 2" class="custom-image"
                   image="https://oss.xiaoyi120.com/shop2.0/public/coupon_empty.png"
                   description="没有找到的优惠券呢..."
        />
        <van-empty v-if="copuponList3.length === 0 && actived === 3" class="custom-image"
                   image="https://oss.xiaoyi120.com/shop2.0/public/coupon_empty.png"
                   description="没有找到的优惠券呢..."
        />
      </div>
      <!-- 合并按钮 -->
      <template v-if="isCanAdd && actived === 1">
        <div v-if="!showCheck" class="add_btn flex-c-c-c">
          <van-button round color="#0767AB" style="width: 8.69rem;" @click="showCheck = true">合并现金券</van-button>
        </div>
        <div v-else class="choose_item">
          <div class="item_tips flex-c-s-s">
            <div>已选 <span style="color: #ED0D0D;">{{ choose.num }}</span> 张通用现金券</div>
            <div>
              合并后金额 <span style="color: #ED0D0D;">{{ choose.total }}</span> 元，
              满 <span style="color: #ED0D0D;">{{ choose.total>0?choose.total*1+0.01 : 0 }}</span> 元可用。
            </div>
          </div>
          <div class="flex-r-sa-c">
            <van-button round color="#0767AB" plain style="width: 4.11rem;" @click="clearChoose">返回</van-button>
            <van-button round color="#0767AB" style="width: 4.11rem;" @click="confirmeAdd">确定合并</van-button>

          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import './index.scss'
import { user_coupons, mergeCoupon } from '@/api/user'
export default {
  data() {
    return {
      actived: 1,
      copuponList1: [],
      copuponList2: [],
      copuponList3: [],
      activedIndex: 0,
      showCheck: false,
      isCanAdd: false,
      choose: { num: 0, total: 0 },

      tabIndex: 0
    }
  },
  created() {
    this.getCouponList(1)
    this.isCanAdd = this.$route.query.isCanAdd
  },
  methods: {
    backPath() { window.history.back() },
    getCouponList(index) {
      this.actived = index
      user_coupons({ pageNum: 1, pageSize: 999, status: this.actived }).then(res => {
        if (this.actived === 1) {
          this.activedIndex = res.data.data.length
          this.copuponList1 = res.data.data
        } else if (this.actived === 2) { this.copuponList2 = res.data.data } else if (this.actived === 3) { this.copuponList3 = res.data.data }
      })
    },
    choosed_conpon(item) {
      console.log(item)
      if (item.checked) {
        this.choose.num += 1
        this.choose.total += item.money * 1
      } else {
        this.choose.num -= 1
        this.choose.total -= item.money * 1
      }
    },
    clearChoose() {
      this.choose = { num: 0, total: 0 }
      for (const i in this.copuponList1) { this.$set(this.copuponList1[i], 'checked', false) }
      this.showCheck = false
    },
    confirmeAdd() {
      if (this.choose.num > 1) {
        var couponIdList = []
        for (const i in this.copuponList1) {
          if (this.copuponList1[i].checked) { couponIdList.push(this.copuponList1[i].couponId) }
        }
        mergeCoupon({ couponIdList: couponIdList }).then(res => {
          this.clearChoose()
          this.getCouponList(1)
        })
      } else {
        this.$toast({ message: '请选择两张以上优惠券进行合并！', duration: 3 * 1000 })
      }
    },
    changeTab(index) { this.tabIndex = index }
  }
}
</script>

<style scoped>
.coupon_check {
  position: absolute;
  top: 1rem;
  right: 0.78rem;
}
.add_btn {
  position: fixed;
  bottom: 0rem;
  width: 100%;
  height: 2rem;
  background: #fff;
}
.choose_item {
  position: fixed;
  bottom: 0rem;
  width: 100%;
  height: 3.3rem;
  background: #fff;
}
.item_tips {
  height: 1.6rem;
  background: #fdf4f5;
  font-size: 0.37rem;
  padding-top: 0.27rem;
  padding-left: 0.67rem;
}
/* .tab_item_xxx {
  width: 2rem;
  height: 2rem;
  background: rgb(102, 165, 204);
  color: #333;
  line-height: 2rem;
}
.tab_item_actived{
  height: 2.5rem;
} */
</style>
